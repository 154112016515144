a.styled {
  background: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  text-decoration: none;
  cursor: pointer;
  color: -webkit-link;
  color: -moz-hyperlinktext;
}
a.styled > svg:first-child {
  margin-right: 4px;
}
a.styled:hover, a.styled:active {
  outline-width: 0;
}
a.styled:hover {
  background: #bbeffd;
  border-bottom-color: #1a1a1a;
}
a.styled:active {
  color: inherit;
}
a.styled.withIcon {
  display: inline-flex;
  align-items: center;
}

.cardContainer {
  padding-top: 35px;
}
.card {
  /* transition: box-shadow 0.5s, transform 0.5s; */
  margin: 35px;
  margin-top: 0;
}
.card:hover {
  /* background: #FF5722; */
  /* box-shadow: 0 9px 19px rgba(0,0,0,.3),0 7px 6px rgba(0,0,0,.22); */
  /* transform: translateY(-1px) !important; */
}
.cssGrid {
  width: 200px;
  display: grid;
  grid-template-columns: 30px 100px;
}

.cssGrid > div {
  justify-self: start;
  align-self: center;
}
